import ChevronBackward from '@/app/_components/Icon/ChevronBackward'
import ChevronForward from '@/app/_components/Icon/ChevronForward'
import { getDayClassName } from '@/app/_primitives/utils/classnames-utils'
import {
  useContextCalendars,
  useContextDatePickerOffsetPropGetters,
  useContextDaysPropGetters,
} from '@rehookify/datepicker'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface ButtonProps {
  className?: string
  children: ReactNode
}

function DayButton({ className, children, ...props }: ButtonProps) {
  const buttonClassName = twMerge(
    'flex justify-center items-center hover:bg-french-bleu hover:text-white rounded disabled:opacity-50 disabled:cursor-not-allowed',
    className,
  )
  return (
    <button type="button" className={buttonClassName} {...props}>
      {children}
    </button>
  )
}

export function Calendar() {
  const { calendars, weekDays } = useContextCalendars()
  const { dayButton } = useContextDaysPropGetters()
  const { addOffset, subtractOffset } = useContextDatePickerOffsetPropGetters()

  const { days, month, year } = calendars[0]

  return (
    <div className="flex w-280 flex-col">
      <div className="flex flex-col gap-24">
        <div className={'flex flex-row items-center justify-between'}>
          <button
            type="button"
            {...subtractOffset({ months: 1 })}
            aria-label="Previous month">
            <ChevronBackward />
          </button>
          <p className="text-20 font-[500] leading-32">{`${year} ${month}`}</p>
          <button
            type="button"
            {...addOffset({ months: 1 })}
            aria-label="Next month">
            <ChevronForward fill="#8B8B8B" />
          </button>
        </div>
        <div className="grid grid-cols-7 items-center justify-center">
          {weekDays.map((day) => (
            <p
              className="flex h-40 flex-row items-center justify-center text-[#94A3B8]"
              key={`${month}-${day}`}>
              {day}
            </p>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-7 items-center justify-center">
        {days.map((dpDay) => (
          <DayButton
            key={dpDay.$date.toDateString()}
            className={getDayClassName('text-16', dpDay)}
            {...dayButton(dpDay)}>
            {dpDay.day.substring(0, 2)}
          </DayButton>
        ))}
      </div>
    </div>
  )
}
