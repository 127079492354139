import { cn } from '@/app/_primitives/utils/cn'
import React, { ForwardedRef } from 'react'

type Props = {
  size?: 'sm' | 'lg'
  error?: boolean
  variant?: 'default' | 'dark'
  rows?: number
} & Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'size'>

function TextAreaComponent(
  {
    className,
    error = false,
    size = 'lg',
    variant = 'default',
    rows,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  return (
    <textarea
      ref={ref}
      className={cn(
        'rounded-15 border border-[#D9DADB] bg-[#FFFFFFCC]',
        'focus-visible:ring-2 ring-[#1D7AFC]',
        'rounded-15 focus:outline-none focus-visible:outline-none px-20',
        'placeholder:text-[#8B8B8B] bg-[#FFFFFFCC] min-h-60',
        {
          'border-[#FF9840]': error && variant === 'default',
          'border-[#FF2B2B]': error && variant === 'dark',
        },
        {
          'c-b3 py-17': size === 'lg',
          'c-b4 py-13': size === 'sm',
        },
        className,
      )}
      rows={rows}
      {...props}
    />
  )
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, Props>(
  TextAreaComponent,
)

TextArea.displayName = 'TextArea'
