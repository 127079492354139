import { ChevronDown, ChevronUp } from 'lucide-react'
import React, { useRef, useState } from 'react'
import { cn } from './utils/cn'

export function UpDownControl({
  id,
  nextControlId,
  onChange,
  onConfirmOrFocusLost,
  value,
  onAction,
}: {
  id?: string
  nextControlId?: string
  onChange?: (value: number) => void
  onConfirmOrFocusLost?: (value: number) => void
  value: React.ReactNode
  onAction: (action: 'up' | 'down') => void
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [mode, setMode] = useState<'edit' | 'view'>('view')
  return (
    <div className={'flex flex-col items-stretch gap-20'}>
      <button
        onClick={() => onAction('up')}
        type={'button'}
        className={'flex flex-row justify-center focus-visible:outline-none'}>
        <ChevronUp className={'stroke-txt-secondary'} />
      </button>
      <input
        id={id}
        ref={inputRef}
        onKeyDown={(e) => {
          if (e.key === 'Backspace') return
          if (e.key === 'Enter') {
            e.currentTarget.blur()
            if (nextControlId) {
              const nextControl = document.getElementById(nextControlId)
              nextControl?.focus()
            }
            return
          }
          if (!/^\d$/.test(e.key)) {
            e.preventDefault()
          }
        }}
        onFocus={(e) => e.currentTarget.select()}
        onBlur={(e) => {
          setMode('view')
          onConfirmOrFocusLost?.(+e.target.value)
        }}
        onChange={(e) => onChange?.(+e.target.value)}
        className={cn('text-center c-h4 w-45 text-center focus:outline-none', {
          hidden: mode === 'view',
        })}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
      <p
        onClick={() => {
          if (!onChange) return
          if (inputRef.current) {
            inputRef.current.value = value as string

            setTimeout(() => {
              inputRef.current?.focus()
            }, 300)
          }
          setMode('edit')
        }}
        className={cn('c-h4 min-w-45', {
          hidden: mode === 'edit',
        })}>
        {value}
      </p>
      <button
        onClick={() => onAction('down')}
        type={'button'}
        className={'flex flex-row justify-center focus-visible:outline-none'}>
        <ChevronDown className={'stroke-txt-secondary'} />
      </button>
    </div>
  )
}
