import { DPDay, DPMonth, DPTime, DPYear } from '@rehookify/datepicker'
import clsx from 'clsx'

export const getDayClassName = (
  className: string,
  { selected, disabled, inCurrentMonth, now, range }: DPDay,
) =>
  // eslint-disable-next-line tailwindcss/no-custom-classname
  clsx('day h-40', className, range, {
    'bg-french-bleu text-white hover:bg-slate-700 opacity-100 broder border-french-bleu':
      selected,
    'text-[#CBD5E1] cursor-not-allowed': disabled,
    'text-[#CBD5E1]': !inCurrentMonth,
    'border border-french-bleu': now,
    'bg-[#3366B64D] rounded-0': range === 'in-range',
    'text-white': !inCurrentMonth && range === 'in-range',
    'rounded-e-0': range === 'range-start',
    'rounded-s-0': range === 'range-end',
  })

export const getMonthClassName = (
  className: string,
  { selected, now, disabled }: DPMonth,
) =>
  clsx(className, {
    'bg-slate-700 text-white hover:bg-slate-700 opacity-100': selected,
    'border border-slate-500': now,
    'opacity-25 cursor-not-allowed': disabled,
  })

export const getYearsClassName = (
  className: string,
  { selected, now, disabled }: DPYear,
) =>
  clsx(className, {
    'bg-slate-700 text-white hover:bg-slate-700 opacity-100': selected,
    'border border-slate-500': now,
    'opacity-25 cursor-not-allowed': disabled,
  })

export const getTimesClassName = (
  className: string,
  { selected, disabled }: DPTime,
) =>
  clsx(className, {
    'bg-french-bleu text-white hover:bg-slate-700 opacity-100': selected,
    'opacity-25 cursor-not-allowed': disabled,
  })
