export function boundedNumber(
  value: number,
  upperBound: number,
  lowerBound: number,
) {
  if (value > upperBound) {
    return lowerBound
  }

  if (value < lowerBound) {
    return upperBound
  }

  return value
}
